// import axios from "axios";
import QRCode from "qrcode";

import { CollaborationState } from "@/enums";

export const time_polling_count = 30000; //ms
export const collaborationPerPage = 20;
export const navigatorTabTitle = document.querySelector("title");

import {
  APPLICATION_FORMATS,
  AUDIO_FORMATS,
  IMAGE_FORMATS,
  TEXT_FORMATS,
  VIDEO_FORMATS
} from "../enums";
import { Media } from "../gateways";
import { UI_SNACKBAR_SHOW } from "../store/actions";

export const deepClone = from => JSON.parse(JSON.stringify(from));

export const isNumber = val => !isNaN(val);

const digitsRegex = new RegExp("^\\d+$");
export const isPositiveInteger = val => {
  return digitsRegex.test(String(val));
};

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const hasTokenExpired = timestamp =>
  new Date(timestamp * 1000).getTime() - new Date().getTime() <= 0;

export const downloadMediaById = async id => {
  try {
    const { url, name } = (await Media.get(id)).data;
    downloadMediaByURI(url, name);
  } catch (err) {
    const { response } = err;
    switch (response.status) {
      case 400:
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message:
            "Ops... Tivemos um problema no servidor. Por favor, tente novamente em alguns instantes :)"
        });
        break;
      case 401:
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message: "Você não possui permissão para executar essa ação."
        });
        break;
      default:
        this.$store.dispatch(UI_SNACKBAR_SHOW, {
          message:
            "Ops... Tivemos um problema no servidor. Por favor, tente novamente em alguns instantes :)"
        });
        break;
    }
  }
};

const isChrome = navigator.userAgent.toLowerCase().indexOf("chrome") > -1;
const isSafari = navigator.userAgent.toLowerCase().indexOf("safari") > -1;

export const downloadMediaByURI = async (url, name = "") => {
  //iOS devices do not support downloading. We have to inform user about this.
  if (/(iP)/g.test(navigator.userAgent)) {
    //alert('Your device does not support files downloading. Please try again in desktop browser.');
    window.open(url, "_blank");
    return false;
  }

  //If in Chrome or Safari - download via virtual link click
  if (isChrome || isSafari) {
    //Creating new link node.
    var link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    link.setAttribute("download", name);

    //Dispatching click event.
    if (document.createEvent) {
      var e = document.createEvent("MouseEvents");
      e.initEvent("click", true, true);
      link.dispatchEvent(e);
      return true;
    }
  }

  // Force file download (whether supported by server).
  if (url.indexOf("?") === -1) {
    url += "?download";
  }

  window.open(url, "_blank");
  return true;
};

export const fileToDataUrl = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const urlToDataUrl = async url => {
  var res = await fetch(url);
  var blob = await res.blob();

  return await fileToDataUrl(blob);
};

export const dataUrlToFile = (dataUrl, filename) => {
  const arr = dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const mediatype2iconMap = {
  video: "movie_creation",
  image: "photo",
  audio: "music_note",
  text: "insert_drive_file",
  application: "apps"
};

export const ext2mediatypeMap = {
  ...VIDEO_FORMATS.reduce((accum, i) => ((accum[i] = "video"), accum), {}),
  ...IMAGE_FORMATS.reduce((accum, i) => ((accum[i] = "image"), accum), {}),
  ...AUDIO_FORMATS.reduce((accum, i) => ((accum[i] = "audio"), accum), {}),
  ...TEXT_FORMATS.reduce((accum, i) => ((accum[i] = "text"), accum), {}),
  ...APPLICATION_FORMATS.reduce(
    (accum, i) => ((accum[i] = "application"), accum),
    {}
  )
};

export const getFileExtension = filename => {
  var a = filename.split(".");
  if (a.length === 1 || (a[0] === "" && a.length === 2)) {
    return "";
  }
  return a.pop();
};

export const getDefaultFilter = campaign => {
  let getLevelModDefault;
  let campaignIds = [];
  if (campaign) {
    campaignIds.push(campaign.id);
    getLevelModDefault =
      campaign?.required_approvals > 0
        ? CollaborationState.FirstModerationLevel
        : CollaborationState.Approved;
  } else {
    getLevelModDefault = CollaborationState.FirstModerationLevel;
  }
  return {
    collaborator_name: "",
    collaborator_email: "",
    collaborator_phone: "",
    initial_date: null,
    initial_time: null,
    final_date: null,
    final_time: null,
    collaborator_country: "",
    collaborator_state: "",
    collaborator_city: "",
    collaborator_twitter_id: "",
    collaborator_yt_channel_id: "",
    collaborator_insta_owner_id: "",
    collaborator_verified: null,
    inputtype: [],
    media_type: [],
    moderationLevel: getLevelModDefault,
    order_by: "updated_at",
    retweet: false,
    tweetOriginal: false,
    search_text: [],
    size: collaborationPerPage,
    campaign_ids: campaignIds,
    isAllCampaigns: false
  };
};

export const handleTabTitleChange = (
  terms,
  hasNewCollaborations = false,
  countDiff
) => {
  const defaultReturn = terms.length
    ? `${terms.join(", ")} - Pesquisa Colaborativo`
    : "Colaborativo";
  if (!hasNewCollaborations) {
    navigatorTabTitle.innerText = defaultReturn;
    return;
  }
  navigatorTabTitle.innerText = `(${countDiff}): ${defaultReturn}`;
};

// const googleChartsQrCode = async (
//   url,
//   width,
//   height,
//   encoding = "UTF-8",
//   correctionLevel = "L",
//   margin = 4,
//   apiUrl = "https://chart.googleapis.com",
//   requestTimeout = 60000
// ) => {
//   const googleApiClient = axios.create({
//     baseURL: apiUrl,
//     timeout: requestTimeout,
//     responseType: "blob"
//   });
//   const data = encodeURI(url);

//   const options = {
//     params: {
//       cht: "qr",
//       chs: `${width}x${height}`,
//       chl: data,
//       choe: encoding,
//       chld: `${correctionLevel}|${margin}`
//     }
//   };
//   const response = await googleApiClient.get("/chart", options);
//   return response.data;
// };

export const downloadQrCode = async (url, filename, size = 500) => {
  const data = await QRCode.toDataURL(url, {
    width: size,
    errorCorrectionLevel: "L",
    margin: 4
  });
  await downloadMediaByURI(data, filename);
};

// const convertBlobToBase64 = blob => {
//   return new Promise((resolve, reject) => {
//     try {
//       const reader = new FileReader();
//       reader.readAsDataURL(blob);
//       reader.onloadend = function() {
//         resolve(reader.result);
//       };
//     } catch (err) {
//       reject(err);
//     }
//   });
// };

export const generateQrCodeAsBase64 = async (url, size = 300) => {
  try {
    const qrcodeData = await QRCode.toDataURL(url, {
      width: size,
      height: size,
      errorCorrectionLevel: "L",
      margin: 4
    });
    return qrcodeData;
  } catch (err) {
    //eslint-disable-next-line
    console.error("Error generating QR code:", err);
    throw err;
  }
};
